@import "../../shared/_variables.scss";


.home-container{
    //position: relative;
    background-color: $my-background-color;
    height: auto;
    //max-width: 100%;
}

.home-link{
    background-color: inherit;
    border: none;
    color: $theme-primary-color;
    text-decoration: underline;
    font-size: 1rem;
    font-family: $body-font;
}

.home-btn:hover{
    color: $theme-third-color;
}

.my-title{
    color: $theme-dark-pink-color;
}


.text-color {
    color: $theme-primary-color;
}

.home-content{
    margin-top: 90px;
}


