@import '../../shared/_variables.scss';

.my-background{
    background-color: $my-background-color;
}

.my-color{
    color: $theme-primary-color;
}

// li {
//     font-family: $body-font;
// }