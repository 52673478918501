@import "../../shared/_variables.scss";

.break-line{
  width: 90%;
  margin: auto;
  // border: 1px solid $theme-dark-pink-color;
}

// .footer-container{
//   position: relative;
// }


.my-footer-bg{
    width: 100%;
    height: auto;
    font-size: 0.8rem;
    font-family: $body-font;
    // background-color: #F9F4F4;
}

.my-email{
 color: $theme-primary-color;
 font-size: 1.2rem;
 padding-left: 5px;
 text-decoration: underline;
}

p{
    font-size: 1rem;
}

footer{
  margin-right: 20px;
}

footer a{
  font-size: 0.8rem;
  text-decoration: underline;
  font-family: $body-font;
  color: $theme-primary-color;
}

a:hover{
  color: $theme-third-color
}

.contact-group a {
  font-size: 2rem;
  color: black;
}

.contact-group a:hover {
  text-decoration: none;
  color: $theme-primary-color;
}


.social-icons .link {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px dashed $theme-line-color;
  background-clip: content-box;
  padding: 5px;
  transition: .5s;
  // color: #D7D0BE;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.5rem;
} 


.social-icons .link i{
  display: block;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-icons .link:hover {
  padding: 5px;
  color: white;
  margin-left: -5px;
  transform: translateX(10px) rotate(360deg);
}

.social-icons .link.linked {
  background-color: #0E76A8;
  color: white;
    
}
  
.social-icons .link.github {
  background-color: black;
  color: white;
   
}
  
.social-icons .link.behance {
  background-color: #234ef3;
  color: white;
    
}
  