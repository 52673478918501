@import '../../../shared/_variables.scss';



.bottom-nav {
  color: black
}

.bottom-nav:hover{
    color: $theme-primary-color;
}
