@import '../../shared/_variables.scss';


a {
  font-size: 1.5rem;
  font-family: $header-font;
}

.nav-bg-color{
  background-color: white;
}


.navbar-light .navbar-brand, .router-link {
  color: black;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus, .router-link:hover, .router-link:focus {
  color: $theme-primary-color;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: $theme-primary-color;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: $theme-primary-color;
}


.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: $theme-primary-color;
}

.navbar-brand {
  cursor: pointer;
}