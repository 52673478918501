@import './shared/_variables.scss';

.App-logo {
  height: 30vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $my-background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.intro-font{
  font-size: 1.5rem;
  font-family: $header-font;
  text-align: left;
  color: $theme-primary-color;
  margin-bottom: 18px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.my-enter-btn {
  font-size: 2rem;
  font-family: $header-font;
}

.my-enter-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.my-enter-btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.my-enter-btn:hover span {
  padding-right: 25px;
}

.my-enter-btn:hover span:after {
  opacity: 1;
  right: 0;
}

