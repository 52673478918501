@import "../../shared/_variables.scss";


figure.hover-content{
    color: #fff;
    position: relative;
    overflow: hidden;
    margin: 10px;
    min-width: 220px;
    max-width: 550px;
    max-height: 550px;
    width: 100%;
    //background: #000000;
    text-align: center;
    font-family: $body-font;
}


figure.hover-content * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  figure.hover-content img {
    opacity: 1;
    width: 100%;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
  }

  figure.hover-content figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    // padding: 3em 3em;
    width: 100%;
    height: 100%;
  }

  figure.hover-content figcaption::before {
    position: absolute;
    top: 50%;
    right: 30px;
    bottom: 50%;
    left: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    content: '';
    opacity: 0;
    background-color: #ffffff;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  figure.hover-content h5,
  figure.hover-content h6,
  figure.hover-content p,
  figure.hover-content a,
  figure.hover-content button {
    margin: 0 0 5px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s,-webkit-transform 0.35s,-moz-transform 0.35s,-o-transform 0.35s,transform 0.35s;
  }

  figure.hover-content h5 {
    // word-spacing: -0.15em;
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 10px;
    -webkit-transform: translate3d(0%, 50%, 0);
    transform: translate3d(0%, 50%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    color: white;
  }

figure.hover-content h6{
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  figure.hover-content p{
    font-weight: 200;
    font-size: 0.8rem;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }


  figure.hover-content a {
    color: #ffffff;
    text-decoration: underline;
    font-size: 1rem;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  figure.hover-content button {
    opacity: 0;
    background-color: inherit;
    color: #ffffff;
    text-decoration: underline;
    font-size: 1.5rem;
    font-family: $header-font;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    border: none;
  }

  // figure.hover-content:hover img {
  //   opacity: 0.35;
  // }

  figure.hover-content:hover figcaption h5 {
    opacity: 1;
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  figure.hover-content:hover figcaption p,
  figure.hover-content:hover figcaption h6 {
    opacity: 0.9;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  figure.hover-content:hover figcaption a, figure.hover-content:hover figcaption button {
    opacity: 0.9;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  figure.hover-content:hover figcaption::before {
    background: rgba(255, 255, 255, 0);
    top: 25px;
    bottom: 25px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  figure.hover-content:hover figcaption a:hover, figure.hover-content:hover figcaption button:hover {
    color: $theme-line-color;
    //text-transform: capitalize;
  }



  /*Zoom In*/
.hover-content img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}
.hover-content:hover img {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}



//=================================

// #greyscale img{
//     -webkit-filter: grayscale(100%);
//     filter: grayscale(100%);
//     -webkit-transition: .3s ease-in-out;
//     transition: .3s ease-in-out; 
// }

// #greyscale:hover img{
//     -webkit-filter: grayscale(0);
//     filter: grayscale(0);
// }

// .img-thumbnail{
//   border: 3px solid white;
// }