/* mobile display only */
@media only screen and (max-width: 767px){
    .my-enter-btn{
        font-size: 1rem;
    }
    
    .intro-font{
        font-size: 2rem;
      }

    .my-enter-btn {
        font-size: 1.5rem;
    }

    .my-title {
        font-size: 1.6rem;
    }

    .back-to-top{
        display: none;
    }

    .modal-title {
       font-size: 2rem;;
    }

    h1 {
        font-size: 1.8rem;;
    }

    .page-title, h2 {
        font-size: 1.5rem;;
    }

    h4 {
        font-size: 1.2rem;;
    }

    .social-icons {
        padding-bottom: 6%;
    }

    .social-icons .link {
        width: 45px;
        height: 45px;
        font-size: 0.7rem;
    } 
    
    
    .social-icons .link:hover {
        padding: 5px;
        color: white;
        margin-left: -5px;
        transform: translateX(10px) rotate(360deg);
    }


    .my-img-size{
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }

    .tag-size {
        margin: auto;
        //width: 90%;
        text-align: left;
        padding-bottom: 10px;
        font-size: 1rem;
        //padding-left: 30px;
    }

    .project-link-fs {
        font-size: 1rem;
    }



    // .my-footer-bg {
    //     padding-left: 2.5rem;
    //     padding-right: 2.5rem;
    // }

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px){
    p {
        font-size: 1.1rem;;
      }
    
    .home-link{
        font-size: 1.1rem;
    } 
   
    .my-footer-bg, .my-footer-bg a{
        font-size: 1rem;
    }
    
    
    .intro-font {
        font-size: 3rem;
        margin-bottom: 60px;
    }

    .my-title {
        font-size: 1.6rem;
    }

    .my-btn {
        padding: 7px 20px;       
        font-size: 1rem;
    }

    .page-title {
        font-size: 2.5rem;
    }
   
    figure.hover-content h5 {
        font-size: 1.8rem;
    }
    
    figure.hover-content h5 {
        padding-bottom: 10px;
    }

    .my-img-size {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        
    }

    .m-position {
        text-align: center;
    }

    .social-icons {
        padding-bottom: 5%;
        padding-top: 2%;
    }

}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    // p, ul, ol {
    //     font-size: 1.1rem;;
    //   }
    h2 {
        font-size: 2rem;   
    }

    .home-link{
        font-size: 1.1rem;
    }

    
    .intro-font {
        font-size: 3.5rem;
        margin-bottom: 100px;
    }
        

    .my-btn {    
        padding: 10px 20px; 
        font-size: 1rem;
    }

  
    .page-title {
        font-size: 2.5rem;
    }

    figure.hover-content h5 {
        font-size: 1.8rem;
    }
     
    figure.hover-content p{ 
        font-size: 1rem;
    }

    .back-to-top{
        color: #3317E2;
        font-size: 1.2rem;
    }

    .my-img-size{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }


}


/* Large devices (tablet landscape/laptops/desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {


    .home-link{
        font-size: 1.1rem;
    }

    .my-title {
        font-size: 1.6rem;
    }

    .my-footer-bg, .my-footer-bg a{
        font-size: 0.9rem;
    }


    .my-img-size{
        max-width: 100%;
        height: 100vh;
        object-fit: cover;
       // object-position: bottom;
    }

    .home-container {
        max-width: 100%;
        height: auto;
    }

    .social-icons {
        padding-bottom: 3%;
    }
    // .figure-img {
    //     max-width: 80%;
    //     height: 400px;
    // }

    // .project-desc {
    //     justify-content: center;
    // }



    

}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .intro-font {
        font-size: 3.7rem;
        margin-bottom: 100px;
    }

    .my-title {
        font-size: 1.6rem;
    }

    .page-title {
        font-size: 3rem;
    }

    figure.hover-content h5 {
        font-size: 2rem;
    }
    
    figure.hover-content a {
        font-size: 1.2rem;
    }

    .back-to-top{
        color: #3317E2;
        font-size: 1.2rem;
    }
    

    .my-img-size{
        height: 100vh;
        object-fit: cover;
    }

    // .figure-img {
    //    max-width: 50%;
    //    height: 420px;
    // }

}


